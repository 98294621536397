:root {
    --trigger-button-height: 56px;
    --ul-width: 220px;
    --location-dropdown-border-color: rgba(255, 166, 23, 0.5);
}

.dropdown-wrapper {
    position: relative;
    align-self: flex-start;
    width: 200px;
    caret-color: transparent;
}

.dropdown-wrapper>.trigger-button {
    position: relative;
    height: var(--trigger-button-height);
    width: var(--ul-width);
    text-align: center;
    background-color: #E69D25;
    border: none;
    border-radius: 10px;
    font-size: 12px;
    color: #FFF;
}

.dropdown-wrapper>.trigger-button::after {
    content: "↓";
    position: absolute;
    right: 2px;
    bottom: 1px;
    font-size: 20px;
}

.dropdown-wrapper>.trigger-button:hover {
    background-color: #f9ae47;
}

.dropdown-wrapper>ul {
    position: absolute;
    color: #E69D25;
    list-style: none;
    max-height: 12em;
    width: var(--ul-width);
    overflow: auto;
    padding-left: 0;
    top: calc(var(--trigger-button-height) + 5px);
    left: 0;
    border-top: 1px solid var(--location-dropdown-border-color);
    border-right: 1px solid var(--location-dropdown-border-color);
    border-bottom: 1px solid var(--location-dropdown-border-color);
    border-left: 1px solid var(--location-dropdown-border-color);
    border-right: 1px solid var(--location-dropdown-border-color);
    border-radius: 10px;
    text-align: center;
}

.dropdown-wrapper>ul>li {
    scale: 0.98;
    padding: 2px;
    border-bottom: 1px solid rgba(204, 204, 204, 0.3);
}

.dropdown-wrapper>ul>li:last-child {
    border-bottom: none;
}

.dropdown-wrapper>ul>li:hover {
    background-color: #f0eded;
    cursor: pointer;
}


*:disabled {
    background-color: #cccccc;
    color: #777777;
    cursor: not-allowed;
    opacity: 0.7;
    box-shadow: none;
}