@keyframes typing {
  from {
    width: 0
  }

  to {
    width: 48%
  }
}

@keyframes blink-caret {
  from, to {
    border-color: transparent
  }

  50% {
    border-color: #E69D25;
  }
}

* {
  margin: 0;
  box-sizing: border-box;
  font-size: medium;
}

.App {
  display: grid;
  grid-template-areas: "form preview"
                       "form emailbackground"
                       "form emailbackground";
  grid-template-columns: 350px auto;
  grid-template-rows: 10px;
  font-family: Arial, Helvetica, sans-serif;
}

.preview-text {
  margin-top: 2%;
  grid-area: preview;
}

.email-background {
  grid-area: emailbackground;

}

::-webkit-scrollbar {
  width: 3px;
  background: transparent;
}

::-webkit-scrollbar-track {
  box-shadow: inset 0 0 5px grey;
}

::-webkit-scrollbar-thumb {
  background: #E69D25;
  border-radius: 10px;
}

.App-blur {
  filter: blur(5px);
}

.BrowserWarning {
  position: fixed;
  top: 40%;
  left: 55%;
  transform: translate(-50%, -50%);
  width: 500px;
  height: 300px;
  background-color: white;
  border: 1px solid rgba(82, 82, 82, 0.5);
  z-index: 1000;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  box-shadow: 0px 0px 10px rgba(47, 47, 47, 0.5);
}

.BrowserWarning>div {
  position: relative;
  margin-top: 6%;
}


.BrowserWarning>div>#coverspan {
  position: absolute;
  width: 170px;
  height: 17px;
  background-color: white;
  transform: translate(5%, -100%);
  z-index: 0;
  right: 0;

}

.BrowserWarning>div>#typingspan {
  background-color: white;
  overflow: hidden;
  border-right: .15em solid #E69D25;
  white-space: nowrap;
  letter-spacing: .15em;
  transform: translate(5%, -100%);
  font-family: Helvetica;
  font-style: italic;
  font-size: 10px;
  font-weight: 600;
  color: #E69D25;
  position: absolute;
  right: 0;
  z-index: 1;
  animation:
    typing 3.5s steps(40, end),
    blink-caret 1s step-end infinite;
}

.BrowserWarning>p {
  justify-content: center;
  margin-bottom: 5%;
  text-align: center;
  font-size: large;
  margin-inline: 20px;
}

.BrowserWarning button {
  position: relative;
  color: white;
  background-color: rgba(207, 46, 46, 1);
  justify-self: flex-end;
  align-self: flex-end;
  border: none;
  margin-right: 20px;
  margin-bottom: 20px;
  bottom: 0;
  padding: 5px 10px;
  font-size: medium;
  cursor: pointer;
  transition: text-decoration 1s ease-in-out;
}

.BrowserWarning button::after {
  content: '';
  position: absolute;
  bottom: 3px;
  left: 15px;
  width: 0;
  height: 1px;
  background-color: #E69D25;
  transition: width 0.5s ease-in-out;
}

.BrowserWarning button:hover::after {
  width: 82%;
}