.email-background {
    margin-top: 5%;
    padding: 5px;
    width: 70%;
    background-color: #ffffff;
    display: grid;
    grid-template-columns: 100px 1fr;
    grid-template-rows: repeat(11, 50px);
    row-gap: 10px;
    column-gap: 5px;
}

.sigpreviewdivider {
    margin-top: 100%;

}

.send__button {
    grid-area: 1 / 1 / 1 / 1;
}

.to__Button {
    height: 56px;
    grid-area: 2 / 1 / 2 / 2;
}

.to__Field {
    grid-area: 2 / 2 / 2 / 3;
}

.subject__Field {
    grid-area: 3 / 1 / 3 / 3;
}

.content__Field {
    grid-area: 4 / 1 / 4 / 3;
}

.email-background>.signature__preview__grid_container{
    margin-top: 25%;
    margin-left: 15%;
    grid-row-start: 8;
}