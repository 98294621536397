@import url('https://fonts.googleapis.com/css2?family=Shadows+Into+Light&display=swap');

.admin_assistant_tooltip__container>.admin_assistant__tooltip {
    width: 16em;
    text-align: center;
    z-index: 99;
}
.q3star__text {
    font-family: "Shadows Into Light", cursive;
    font-weight: 400;
    font-style: normal;
    margin-bottom: 0;
}

.q3star__logo {
    width: 100px;
    height: 100px;
    margin: 0 15px 0 15px;
}

.form__container {
    display: flex;
    justify-content: center;
    background-color: #f1f4f5;
    height: auto;
    padding-bottom: 5px;
    border-radius: 10px;
    width: 300px;
    margin: 2px 0 0 2px;
}

.form__container>form>div {
    display: flex;
    flex-direction: column;
    align-items: center;
    /* Center content horizontally in the div */
    padding: 5px;
}
