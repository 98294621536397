.preview___wrapper{
    display: flex;
    position: absolute;
}

#slogan {
    position: absolute;
    right: 3%;
    margin-bottom: 0;
}

#copybutton {
    display: block;
    position: relative;
    margin-left: 32%;
    margin-top: 3%;
    background-color: #E69D25;
    border: none;
    color: white;
    padding: 10px 35px;
    text-decoration: none;
    font-size: 16px;
    cursor: pointer;
    border-radius: 5px;
    transition: background-color 0.3s, transform 0.3s;
}

#copybutton:hover {
    background-color: #f9ae47;
}

#copybutton span {
    position: absolute;
    left: 88%;
    top: 20%;
    transform: translate(10%, -50%);
    color: green;
    font-size: 14px;
}

#leftcell {
    width: 60% !important;
    line-height: 17px;
}

#rightcell {
    height: 100px;
    display: grid !important;
    grid-template-areas:
    "a"
    "b"
    "c"
    "d";
}

#logo {
    grid-area: b;
}

#slogan {
    grid-area: c;
}

.loading_oval {
    position: absolute;
    top: 9%;
    margin-left: 37%;
}

.phonenumbers::before, .phonenumbers::after {
    content: "\00a0\00a0";
}